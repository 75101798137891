import React, { useMemo } from 'react';

import { lazyForPaint } from 'react-loosely-lazy';

import { type LinkPickerProps } from '@atlaskit/link-picker';

import { type LinkPickerPluginsConfiguration } from '../../useLinkPickerPlugins';

import type { EditorLinkPickerRootComponentWithPluginsProps } from './root-component';

const LazyEditorLinkPickerRootComponentWithPlugins = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "@atlaskit-internal_editor-link-picker-root-component" */
			'./root-component'
		),
);

const DUMMY_PLUGIN_KEY = '__dummy_plugin__';

const PLACEHOLDER_PLUGINS: Required<LinkPickerProps>['plugins'] = [
	{
		tabKey: DUMMY_PLUGIN_KEY,
		tabTitle: 'DUMMY_PLUGIN',
		resolve: () => Promise.resolve({ data: [] }),
	},
];

export const useLinkPickerEditorProps = (
	/**
	 * Baseline set of props to provide to the link picker
	 */
	props?: Partial<LinkPickerProps>,
	/**
	 * The configuration used to control what plugins/tabs are available
	 * MUST be known on the first render as it is used to dynamically create the plugins and
	 * Editor is not yet reactive to changes in the resulting plugins
	 */
	config?: LinkPickerPluginsConfiguration,
): Partial<LinkPickerProps> => {
	// TODO: When editor reactivity is fixed,
	// replace the below with useLinkPickerPlugins
	const WrapperComponent = useMemo(
		() => (props: EditorLinkPickerRootComponentWithPluginsProps) => {
			return <LazyEditorLinkPickerRootComponentWithPlugins {...props} config={config} />;
		},
		[config],
	);

	return useMemo(() => {
		return {
			...(props ?? {}),
			// We are providing an array of placeholder plugins
			// otherwise editor will not position the link picker correctly
			plugins: PLACEHOLDER_PLUGINS,
			component: WrapperComponent,
		};
	}, [WrapperComponent, props]);
};
